<template>
  <v-breadcrumbs
      :items="items"
      divider=">"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          v-if="item.to"
          :to="item.to"
          :link="true"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        v-else
        disabled>
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs2",

  props: {
    items: {type: Array, default: () => []}
  }
}
</script>

<style scoped>

</style>